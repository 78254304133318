<template>
    <div class="point-container">
        <router-link
            v-for="(item, index) in linePointList"
            :key="index"
            :to="{ path: '/point/details', query: { id: item.id } }"
            class="point-item"
        >
            <!-- <div class="point-item"> -->
            <van-image
                width="100%"
                height="100%"
                radius="10"
                :src="item.logoUrl"
                fit="cover"
            />
            <div class="point-title">{{ item.lpTitle }}</div>
            <!-- </div> -->
        </router-link>
    </div>
</template>

<script>
import { getLinePoint } from "@/service";
export default {
    data() {
        return {
            linePointList: [],
        };
    },
    mounted() {
        this.getLinePoint();
    },
    methods: {
        async getLinePoint() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                commonId: "",
            };
            const { data } = await getLinePoint(params);
            this.linePointList = data.list;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin";
@import "@/style/_varibles";

.point-container {
    padding: 30px;
    @include wh(100%, auto);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .point-item {
        @include fj(center);
        width: 48%;
        text-align: center;
        margin-top: 20px;
        border-radius: 20px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.17);
        height: 400px;
        position: relative;
        .point-title {
            @include sc(28px, #fff);
            position: absolute;
            bottom: 12px;
            text-align: center;
        }
    }
}
</style>